import * as React from "react";
import type { HeadFC } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";

const About: React.FC = () => {
  return (
    <Layout>
      <h1 className="text-3xl font-bold underline">About</h1>
    </Layout>
  );
};

export default About;

export const Head: HeadFC = () => <Seo title="このサイトについて" />;
